var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.currentUser && (_vm.currentUser.type == 'TeleConsultant' || _vm.currentUser.type == 'NRP' || _vm.currentUser.type == 'CancelledOrder' || _vm.currentUser.type == 'ToRemind') ? _c('div', [_vm.showButtonStart == true ? _c('div', {
    staticClass: "min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-3 items-center"
  }, [_c('div', {
    staticClass: "bg-white w-full rounded-lg shadow"
  }, [_c('div', {
    staticClass: "flex justify-center"
  }, [_vm.showButtonStart == true ? _c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/Confirmation.svg"),
      "alt": "Image",
      "width": "40%"
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "flex justify-center"
  }, [_c('div', {
    class: _vm.showButtonStart == true ? 'flex justify-center w-1/3 mt-1 pb-20 pt-1 space-x-2' : 'flex justify-center w-1/3 mt-1  pt-1 space-x-2'
  }, [_c('div', {
    staticClass: "flex flex-col"
  }, [_c('div', {
    staticClass: "mt-1 flex justify-center"
  }, [_vm.showButtonStart == true ? _c('button', {
    staticClass: "flex bg-green-500 rounded-lg font-bold text-white text-center px-10 py-3 transition duration-300 ease-in-out hover:bg-green-600 mr-6 focus:outline-none",
    on: {
      "click": _vm.startConfirmation
    }
  }, [!_vm.vShow ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("start")) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t("continue")) + " ")]), _c('svg', {
    staticClass: "inline ml-6 w-6 stroke-current text-white stroke-2",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('line', {
    attrs: {
      "x1": "5",
      "y1": "12",
      "x2": "19",
      "y2": "12"
    }
  }), _c('polyline', {
    attrs: {
      "points": "12 5 19 12 12 19"
    }
  })])]) : _vm._e()])])])])])]) : _vm._e(), _vm.order._id && _vm.showButtonStart == false ? _c('div', {
    staticClass: "font-sans bg-grey-lighter flex flex-col min-h-screen w-full"
  }, [_vm._m(0), _c('div', {
    staticClass: "flex-grow container mx-auto px-4 pb-8"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "card-1"
  }, [_c('div', {
    staticClass: "card-header-1"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('h5', {
    staticClass: "card-title flex-grow-1 mb-0"
  }, [_c('svg', {
    staticClass: "inline-block fill-current mdi mdi-truck-fast-outline align-middle me-1 text-muted",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "d": "M18 6v-6h-18v18h6v6h18v-18h-6zm-12 10h-4v-14h14v4h-10v10zm16 6h-14v-14h14v14zm-6.793-4v-.757c1.529-1.381 2.425-2.116 2.436-2.896 0-.436-.255-.782-.872-.782-.46 0-.864.23-1.144.437l-.354-.889c.396-.304 1.029-.551 1.745-.551 1.218 0 1.875.708 1.875 1.67 0 1.145-1.043 1.996-1.917 2.724v.016h2.024v1.028h-3.793zm-4.097-3.544h1.11l.624.925.617-.925h1.075l-1.164 1.659 1.31 1.885h-1.11l-.741-1.118-.74 1.118h-1.091l1.275-1.865-1.165-1.679z"
    }
  })]), _vm._v(_vm._s(_vm.$t("Double orders")) + " ")]), _c('div', {
    staticClass: "flex-shrink-0"
  }, [_vm._v("(" + _vm._s(_vm.dataDouble.length) + ")")])])]), _c('div', {
    staticClass: "card-body-1"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('svg', {
    staticClass: "inline-block fill-current mdi mdi-truck-fast-outline align-middle me-1 text-black",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "60",
      "height": "60",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "d": "M18 6v-6h-18v18h6v6h18v-18h-6zm-12 10h-4v-14h14v4h-10v10zm16 6h-14v-14h14v14zm-6.793-4v-.757c1.529-1.381 2.425-2.116 2.436-2.896 0-.436-.255-.782-.872-.782-.46 0-.864.23-1.144.437l-.354-.889c.396-.304 1.029-.551 1.745-.551 1.218 0 1.875.708 1.875 1.67 0 1.145-1.043 1.996-1.917 2.724v.016h2.024v1.028h-3.793zm-4.097-3.544h1.11l.624.925.617-.925h1.075l-1.164 1.659 1.31 1.885h-1.11l-.741-1.118-.74 1.118h-1.091l1.275-1.865-1.165-1.679z"
    }
  })]), _c('h5', {
    staticClass: "fs-16 mt-2"
  }, [_vm.loadingDouble ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("Checking")) + " ... "), _vm._m(1)]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t("Number of order doubled by this phone")) + " (" + _vm._s(_vm.dataDouble.length) + ") "), _vm.dataDouble.length > 0 ? _c('span', [_c('svg', {
    staticClass: "cursor-pointer inline-block fill-current mdi mdi-truck-fast-outline align-middle me-1 text-black",
    attrs: {
      "width": "24",
      "height": "24",
      "clip-rule": "evenodd",
      "fill-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "2",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    },
    on: {
      "click": function click($event) {
        return _vm.$vm2.open('ModalDouble');
      }
    }
  }, [_c('path', {
    attrs: {
      "d": "m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm.002 3c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5z",
      "fill-rule": "nonzero"
    }
  })])]) : _vm._e()])]), _c('p', {
    staticClass: "phone mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("Phone number")) + ": " + _vm._s(_vm.order.customer.phone) + " ")])])])])]), _c('div', {
    staticClass: "bg-white border-t border-b sm:border-l sm:border-r sm:rounded shadow"
  }, [_c('div', {
    staticClass: "border-b"
  }, [_c('div', {
    staticClass: "flex justify-between"
  }, [_c('div', {
    staticClass: "flex justify-between card-header-1"
  }, [_c('h5', {
    staticClass: "card-title flex-grow-1 mb-0"
  }, [_c('svg', {
    staticClass: "inline-block fill-current mdi mdi-truck-fast-outline align-middle me-1 text-muted",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "d": "M13.25 7c0 .69-.56 1.25-1.25 1.25s-1.25-.56-1.25-1.25.56-1.25 1.25-1.25 1.25.56 1.25 1.25zm10.75 5c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-2 0c0-5.514-4.486-10-10-10s-10 4.486-10 10 4.486 10 10 10 10-4.486 10-10zm-13-2v2h2v6h2v-8h-4z"
    }
  })]), _vm._v(" " + _vm._s(_vm.$t("detail_order")) + " ")])])])]), _c('div', {
    staticClass: "flex md:flex-row flex-col m-auto my-5 mx-4"
  }, [_c('div', {
    staticClass: "md:w-1/4 p-4 flex justify-center items-center bg-gray-200 mx-2 rounded box-3"
  }, [_vm._m(2), _c('div', {
    staticClass: "ml-2"
  }, [_c('div', {
    staticClass: "text-2xl uppercase leading-8"
  }, [_vm._v(" " + _vm._s(_vm.$t("order")) + " ")]), _c('div', [_vm._v(_vm._s(_vm.order.id))])])]), _c('div', {
    staticClass: "md:w-1/4 p-4 flex justify-center items-center bg-gray-200 mx-2 rounded box-3"
  }, [_vm._m(3), _c('div', {
    staticClass: "ml-2"
  }, [_c('div', {
    staticClass: "text-2xl uppercase leading-8"
  }, [_vm._v(" " + _vm._s(_vm.$t("order_date")) + " ")]), _c('div', [_vm._v(_vm._s(_vm.order.date))])])]), _c('div', {
    staticClass: "md:w-1/4 p-4 flex justify-center items-center bg-gray-200 mx-2 rounded box-3"
  }, [_vm._m(4), _c('div', {
    staticClass: "ml-2"
  }, [_c('div', {
    staticClass: "text-2xl uppercase leading-8"
  }, [_vm._v(" " + _vm._s(_vm.$t("seller")) + " ")]), _c('div', [_vm._v(_vm._s(_vm.order.seller.fullName || _vm.order.seller.email))])])]), _c('div', {
    staticClass: "md:w-1/4 p-4 flex justify-center items-center bg-gray-200 mx-2 rounded box-3"
  }, [_vm._m(5), _c('div', {
    staticClass: "ml-2"
  }, [_c('div', {
    staticClass: "text-2xl uppercase leading-8"
  }, [_vm._v(" " + _vm._s(_vm.$t("store")) + " ")]), _c('div', [_vm._v(_vm._s(_vm.order.source))])])])])]), _vm.order.remind ? _c('div', {
    staticClass: "flex p-4 mt-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800",
    attrs: {
      "id": "alert-border-1",
      "role": "alert"
    }
  }, [_c('svg', {
    staticClass: "flex-shrink-0 w-5 h-5",
    attrs: {
      "fill": "currentColor",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z",
      "clip-rule": "evenodd"
    }
  })]), _c('div', {
    staticClass: "ml-3 text-sm font-medium"
  }, [_vm._v(" Cette commande est déjà reportée pour la date " + _vm._s(_vm.$moment(_vm.order.remind).format("yyyy-MM-DD HH:mm")) + ". ")])]) : _vm._e(), _c('div', {
    staticClass: "flex flex-wrap -mx-4 py-4 mb-6"
  }, [_c('div', {
    staticClass: "w-full mb-6 lg:mb-0 lg:w-1/2 px-4 flex flex-col"
  }, [_c('div', {
    staticClass: "flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden"
  }, [_c('div', {
    staticClass: "flex justify-between"
  }, [_c('div', {
    staticClass: "flex justify-between card-header-1 -mb-px"
  }, [_c('h5', {
    staticClass: "card-title flex-grow-1 mb-0"
  }, [_c('svg', {
    staticClass: "inline-block fill-current mdi mdi-truck-fast-outline align-middle me-1 text-muted",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd"
    }
  }, [_c('path', {
    attrs: {
      "d": "M23 6.066v12.065l-11.001 5.869-11-5.869v-12.131l11-6 11.001 6.066zm-21.001 11.465l9.5 5.069v-10.57l-9.5-4.946v10.447zm20.001-10.388l-9.501 4.889v10.568l9.501-5.069v-10.388zm-5.52 1.716l-9.534-4.964-4.349 2.373 9.404 4.896 4.479-2.305zm-8.476-5.541l9.565 4.98 3.832-1.972-9.405-5.185-3.992 2.177z"
    }
  })]), _vm._v(" " + _vm._s(_vm.$t("order_products")) + " ")])])]), _c('div', {
    staticClass: "antialiased font-san"
  }, [_c('div', {
    staticClass: "container mx-auto"
  }, [_c('div', {
    staticClass: "overflow-x-auto"
  }, [_c('div', {
    staticClass: "inline-block min-w-full rounded-lg overflow-hidden"
  }, [_c('table', {
    staticClass: "min-w-full leading-normal"
  }, [_c('thead', [_c('tr', {
    staticClass: "bg-gray-200 text-gray-600 uppercase text-sm leading-normal"
  }, [_c('th', {
    staticClass: "py-2 px-2 text-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("product")) + " ")]), _c('th', {
    staticClass: "py-2 px-2 text-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity")) + " ")]), _c('th', {
    staticClass: "py-2 px-2 text-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("unit_price")) + " ")]), _c('th', {
    staticClass: "py-2 px-2 text-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("Page produit")) + " ")]), _c('th', {
    staticClass: "py-2 px-2 text-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("action")) + " ")])])]), _vm._l(_vm.order.details, function (it, indx) {
    return _c('tbody', {
      key: it._id
    }, [_c('tr', {
      staticClass: "border-b border-gray-200 hover:bg-gray-100",
      class: indx % 2 == 0 ? 'bg-gray-50' : ''
    }, [_c('td', {
      staticClass: "py-2 px-2 text-left"
    }, [_c('div', {
      staticClass: "flex items-center"
    }, [_c('div', {
      staticClass: "flex-shrink-0 w-10 h-10"
    }, [_c('img', {
      staticClass: "h-10 w-10 rounded-md mx-auto",
      attrs: {
        "alt": "...",
        "src": it.product.picture ? it.product.picture : 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
      }
    })]), _c('div', {
      staticClass: "ml-3"
    }, [_vm._v(" " + _vm._s(it.product.name) + " ")])])]), _c('td', {
      staticClass: "py-2 px-2 text-left"
    }, [_c('p', {
      staticClass: "text-gray-900 whitespace-no-wrap"
    }, [_vm._v(" " + _vm._s(it.quantity) + " ")])]), _c('td', {
      staticClass: "py-2 px-2 text-left"
    }, [_c('p', {
      staticClass: "text-gray-900 whitespace-no-wrap"
    }, [_vm._v(" " + _vm._s(it.unitPrice) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()])]), _c('td', {
      staticClass: "py-2 px-2 text-center"
    }, [it.product.verificationLink ? _c('a', {
      staticClass: "no-underline relative text-blue-600",
      attrs: {
        "href": it.product.verificationLink,
        "target": "_blank"
      }
    }, [_c('svg', {
      staticClass: "fill-current inline-block",
      attrs: {
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "20",
        "height": "20",
        "viewBox": "0 0 24 24"
      }
    }, [_c('path', {
      attrs: {
        "d": "M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
      }
    })])]) : _c('button', {
      staticClass: "text-white bg-yellow-800 hover:bg-yellow-900 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-yellow-300 dark:text-gray-800 dark:hover:bg-yellow-400 dark:focus:ring-yellow-800",
      attrs: {
        "type": "button"
      }
    }, [_vm._v(" Non défini ")])]), _c('td', {
      staticClass: "py-2 px-2 text-left"
    }, [_c('p', {
      staticClass: "text-gray-900 whitespace-no-wrap cursor-pointer"
    }, [_c('i', {
      staticClass: "material-icons mx-2",
      on: {
        "click": function click($event) {
          return _vm.$vm2.open('updateorders');
        }
      }
    }, [_vm._v(" edit")])])])])]);
  })], 2)])])]), _c('div', {
    staticClass: "px-5 py-5 bg-white flex flex-col xs:flex-row items-center xs:justify-between"
  }, [_c('div', {
    staticClass: "inline-flex mt-2 xs:mt-0"
  }, [_c('span', {
    staticClass: "font-bold text-green-700 p-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("total_price")) + " :")]), _c('span', {
    staticClass: "rounded-full ml-1 p-1 bg-red-100 text-green-700"
  }, [_c('span', {
    staticClass: "justify-center text-center p-1 w-auto rounded-full"
  }, [_vm._v(" " + _vm._s(_vm.order.totalPrice) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()])])])])])])]), _c('div', {
    staticClass: "w-full lg:w-1/2 px-4"
  }, [_c('div', {
    staticClass: "bg-white border-t border-b sm:rounded sm:border shadow"
  }, [_c('div', {
    staticClass: "border-b"
  }, [_c('div', {
    staticClass: "flex justify-between card-header-1 -mb-px"
  }, [_c('h5', {
    staticClass: "card-title flex-grow-1 mb-0"
  }, [_c('svg', {
    staticClass: "inline-block fill-current mdi mdi-truck-fast-outline align-middle me-1 text-muted",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd"
    }
  }, [_c('path', {
    attrs: {
      "d": "M20.628 23h-6.59l-.352-1-13.682-.014c-.005-.005-.002-2.925-.004-3.165-.006-1.898-.012-3.538 2.784-4.182l.474-.108c1.504-.34 3.374-.762 3.724-1.533.041-.092.168-.371-.152-.961-1.926-3.548-2.293-6.665-1.035-8.777.856-1.436 2.42-2.26 4.289-2.26 1.854 0 3.407.812 4.26 2.226 1.258 2.086.901 5.214-1.004 8.807-.314.594-.186.873-.144.966.151.329.571.591 1.125.812l1.96-3.548c.106-.172.286-.263.469-.263.428 0 .694.471.469.837l-1.753 3.173h4.03l-1.752-3.173c-.225-.366.041-.837.469-.837.183 0 .363.091.469.263l2.069 3.747h3.249v2.983h-.828c-.083 0-.156.046-.192.119l-2.352 5.888zm2.371-7.99h-10.999v.996c.396.056.716.302.895.67l1.855 5.325h5.199l2.117-5.291c.188-.391.539-.645.933-.703v-.997zm-10.273-1c-.186-.175-.336-.371-.439-.595-.25-.544-.193-1.166.168-1.849 1.734-3.272 2.101-6.05 1.032-7.824-.677-1.124-1.885-1.742-3.403-1.742-1.53 0-2.748.629-3.429 1.772-1.071 1.797-.697 4.563 1.054 7.789.371.681.432 1.303.184 1.849-.555 1.223-2.517 1.667-4.414 2.096l-.471.107c-1.94.447-2.014 1.256-2.007 3.204l-.002 2.183h12.347l-1.35-3.942c-.013-.019-.086-.065-.168-.065h-.829v-2.983h1.727zm3.273 3.029c0-.276-.224-.5-.5-.5s-.5.224-.5.5v3c0 .276.224.5.5.5s.5-.224.5-.5v-3zm2-.074c0-.276-.224-.5-.5-.5s-.5.224-.5.5v3c0 .276.224.5.5.5s.5-.224.5-.5v-3zm1.997 0c0-.276-.224-.5-.5-.5-.275 0-.5.224-.5.5v3c0 .276.225.5.5.5.276 0 .5-.224.5-.5v-3z"
    }
  })]), _vm._v(" " + _vm._s(_vm.$t("customer_information")) + " ")]), _c('div', {
    staticClass: "flex gap-2"
  }, [_c('button', {
    on: {
      "click": _vm.openWhatsApp
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "40px",
      "height": "40px",
      "viewBox": "0 0 48 48",
      "version": "1.1"
    }
  }, [_c('g', {
    attrs: {
      "id": "Icons",
      "stroke": "none",
      "stroke-width": "1",
      "fill": "none",
      "fill-rule": "evenodd"
    }
  }, [_c('g', {
    attrs: {
      "id": "Color-",
      "transform": "translate(-700.000000, -360.000000)",
      "fill": "#67C15E"
    }
  }, [_c('path', {
    attrs: {
      "d": "M723.993033,360 C710.762252,360 700,370.765287 700,383.999801 C700,389.248451 701.692661,394.116025 704.570026,398.066947 L701.579605,406.983798 L710.804449,404.035539 C714.598605,406.546975 719.126434,408 724.006967,408 C737.237748,408 748,397.234315 748,384.000199 C748,370.765685 737.237748,360.000398 724.006967,360.000398 L723.993033,360.000398 L723.993033,360 Z M717.29285,372.190836 C716.827488,371.07628 716.474784,371.034071 715.769774,371.005401 C715.529728,370.991464 715.262214,370.977527 714.96564,370.977527 C714.04845,370.977527 713.089462,371.245514 712.511043,371.838033 C711.806033,372.557577 710.056843,374.23638 710.056843,377.679202 C710.056843,381.122023 712.567571,384.451756 712.905944,384.917648 C713.258648,385.382743 717.800808,392.55031 724.853297,395.471492 C730.368379,397.757149 732.00491,397.545307 733.260074,397.27732 C735.093658,396.882308 737.393002,395.527239 737.971421,393.891043 C738.54984,392.25405 738.54984,390.857171 738.380255,390.560912 C738.211068,390.264652 737.745308,390.095816 737.040298,389.742615 C736.335288,389.389811 732.90737,387.696673 732.25849,387.470894 C731.623543,387.231179 731.017259,387.315995 730.537963,387.99333 C729.860819,388.938653 729.198006,389.89831 728.661785,390.476494 C728.238619,390.928051 727.547144,390.984595 726.969123,390.744481 C726.193254,390.420348 724.021298,389.657798 721.340985,387.273388 C719.267356,385.42535 717.856938,383.125756 717.448104,382.434484 C717.038871,381.729275 717.405907,381.319529 717.729948,380.938852 C718.082653,380.501232 718.421026,380.191036 718.77373,379.781688 C719.126434,379.372738 719.323884,379.160897 719.549599,378.681068 C719.789645,378.215575 719.62006,377.735746 719.450874,377.382942 C719.281687,377.030139 717.871269,373.587317 717.29285,372.190836 Z",
      "id": "Whatsapp"
    }
  })])])])]), _c('button', {
    on: {
      "click": _vm.makePhoneCall
    }
  }, [_c('svg', {
    staticClass: "w-10 h-10 text-blue-600",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M15 3.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0V5.56l-4.72 4.72a.75.75 0 1 1-1.06-1.06l4.72-4.72h-2.69a.75.75 0 0 1-.75-.75Z",
      "clip-rule": "evenodd"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z",
      "clip-rule": "evenodd"
    }
  })])])])])]), _c('div', [_c('div', {
    staticClass: "text-center px-6 py-4"
  }, [_c('div', {
    staticClass: "relative"
  }, [_c('div', {
    staticClass: "icon bg-green-600 text-white w-6 h-6 absolute flex items-center justify-center p-5",
    staticStyle: {
      "left": "-40px"
    }
  }, [_c('i', {
    staticClass: "fal fa-phone-volume fa-fw text-2xl"
  }, [_c('svg', {
    staticClass: "fill-current h-6 w-6",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1",
      "d": "M16 3h5m0 0v5m0-5l-6 6M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
    }
  })])])]), _c('h5', {
    staticClass: "text-2xl text-gray-900 font-semibold pb-6"
  }, [_vm._v(" " + _vm._s(_vm.$t("call_customer")) + " ")]), _c('div', {
    staticClass: "grid lg:grid-cols-2 gap-6"
  }, [_c('div', {
    staticClass: "border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1",
    class: _vm.style ? 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none border-blue-500 hover:border-blue-600' : 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none'
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 uppercase text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "capitalize bg-white text-gray-600 px-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("full_name")) + " *")])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.order.customer.fullName,
      expression: "order.customer.fullName"
    }],
    class: _vm.style ? 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none border-blue-500 hover:border-blue-600' : 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none',
    attrs: {
      "id": "name",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text",
      "disabled": "",
      "placeholder": _vm.$t('full_name')
    },
    domProps: {
      "value": _vm.order.customer.fullName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.order.customer, "fullName", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1",
    class: _vm.style ? 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none border-blue-500 hover:border-blue-600' : 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none'
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 uppercase text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "capitalize bg-white text-gray-600 px-1",
    attrs: {
      "for": "phone"
    }
  }, [_vm._v(_vm._s(_vm.$t("phone")) + " *")])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.order.customer.phone,
      expression: "order.customer.phone"
    }],
    class: _vm.style ? 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none border-blue-500 hover:border-blue-600' : 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none',
    attrs: {
      "autocomplete": "false",
      "tabindex": "0",
      "type": "tel",
      "disabled": ""
    },
    domProps: {
      "value": _vm.order.customer.phone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.order.customer, "phone", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1",
    class: _vm.style ? 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none border-blue-500 hover:border-blue-600' : 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none'
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 uppercase text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "capitalize bg-white text-gray-600 px-1",
    attrs: {
      "for": "address"
    }
  }, [_vm._v(_vm._s(_vm.$t("adress")) + " *")])])]), _c('p', [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.order.customer.address,
      expression: "order.customer.address"
    }],
    class: _vm.style ? 'py-1 px-1  text-sm outline-none block h-full w-full focus:outline-none border-blue-500 hover:border-blue-600' : 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none',
    attrs: {
      "autocomplete": "false",
      "type": "text",
      "disabled": "",
      "placeholder": _vm.$t('adress')
    },
    domProps: {
      "value": _vm.order.customer.address
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.order.customer, "address", $event.target.value);
      }
    }
  })])])])])])])])])]), _c('div', {}, [_c('div', {
    staticClass: "flex justify-center w-full space-x-2"
  }, [_c('button', {
    staticClass: "w-1/6 p-3 rounded bg-green-600 hover:bg-green-500 text-white font-semibold focus:outline-none",
    on: {
      "click": function click($event) {
        return _vm.open('modal-confirm');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")]), _vm.currentUser && (_vm.currentUser.type == 'TeleConsultant' || _vm.currentUser.type == 'ToRemind') ? [_c('button', {
    staticClass: "w-1/6 p-3 rounded bg-blue-600 hover:bg-blue-500 text-white font-semibold focus:outline-none",
    on: {
      "click": function click($event) {
        return _vm.$vm2.open('myModalUnrached');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("unreached")) + " ")]), _c('button', {
    staticClass: "w-1/6 p-3 rounded bg-purple-600 hover:bg-purple-500 text-white font-semibold focus:outline-none",
    on: {
      "click": function click($event) {
        return _vm.$vm2.open('myModalRemonded');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("to_remind")) + " ")])] : _vm._e(), _c('button', {
    staticClass: "w-1/6 p-2 rounded bg-red-600 hover:bg-red-500 text-white font-semibold focus:outline-none",
    on: {
      "click": function click($event) {
        return _vm.$vm2.open('myModalCancel');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancelled")) + " ")])], 2)]), _c('vue-modal-2', {
    attrs: {
      "name": "modal-confirm",
      "headerOptions": {
        title: _vm.$t('confirmation_order')
      },
      "noFooter": true,
      "modalSize": "lg"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.CloseModal('modal-confirm');
      }
    }
  }, [_c('div', {
    staticClass: "p-4 relative"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _c('div', [_c('div', {
    staticClass: "flex flex-col md:flex-row"
  }, [_c('div', {
    staticClass: "w-full mx-2 flex-1 svelte-1l8159u"
  }, [_c('label', {
    staticClass: "font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase",
    attrs: {
      "for": "shipp-date"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("shipping_date")))]), _c('datetime-v2', {
    attrs: {
      "min-datetime": _vm.$moment(Date.now()).format('yyyy-MM-DD'),
      "placeholder": _vm.$t('shipping_date'),
      "input-class": "focus:outline-none shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
    },
    model: {
      value: _vm.shipping.date,
      callback: function callback($$v) {
        _vm.$set(_vm.shipping, "date", $$v);
      },
      expression: "shipping.date"
    }
  }), _c('label', {
    staticClass: "font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
  }, [_vm._v(" " + _vm._s(_vm.$t("city")))]), _c('div', {
    staticClass: "shadow bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u"
  }, [_c('v-select', {
    attrs: {
      "options": _vm.cities
    },
    model: {
      value: _vm.order.customer.city,
      callback: function callback($$v) {
        _vm.$set(_vm.order.customer, "city", $$v);
      },
      expression: "order.customer.city"
    }
  })], 1)], 1), _c('div', {
    staticClass: "w-full mx-2 flex-1 svelte-1l8159u"
  }, [_c('label', {
    staticClass: "font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
  }, [_vm._v(" " + _vm._s(_vm.$t("phone_2")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.order.customer.shippingPhone,
      expression: "order.customer.shippingPhone"
    }],
    staticClass: "shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
    attrs: {
      "type": "tel",
      "placeholder": _vm.$t('telephone')
    },
    domProps: {
      "value": _vm.order.customer.shippingPhone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.order.customer, "shippingPhone", $event.target.value);
      }
    }
  }), _c('label', {
    staticClass: "font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase",
    attrs: {
      "for": "shipp-add"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("shipping_address")))]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.order.customer.shippingAddress,
      expression: "order.customer.shippingAddress"
    }],
    staticClass: "my-2 p-1 shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
    attrs: {
      "id": "shipp-add",
      "cols": "10",
      "rows": "5",
      "placeholder": _vm.$t('shipping_address_hint')
    },
    domProps: {
      "value": _vm.order.customer.shippingAddress
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.order.customer, "shippingAddress", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "w-full mx-2 flex-1 svelte-1l8159u"
  }, [_c('label', {
    staticClass: "font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
  }, [_vm._v(_vm._s(_vm.$t("add_comment")))]), _c('div', {
    staticClass: "velte-1l8159u",
    staticStyle: {
      "margin-right": "15px"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.order.comment,
      expression: "order.comment"
    }],
    staticClass: "my-2 p-1 shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
    attrs: {
      "id": "commentConfirmation",
      "cols": "10",
      "rows": "3",
      "placeholder": _vm.$t('comment_hint')
    },
    domProps: {
      "value": _vm.order.comment
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.order, "comment", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "flex p-2 mt-4"
  }, [_c('div', {
    staticClass: "flex-auto flex flex-row-reverse"
  }, [_c('button', {
    staticClass: "w-1/6 p-3 rounded bg-green-600 hover:bg-green-500 text-white font-semibold focus:outline-none",
    on: {
      "click": function click($event) {
        return _vm.ConfirmOrderStatus(_vm.order);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")])])])], 1)]), _c('vue-modal-2', {
    attrs: {
      "name": "updateorders",
      "headerOptions": {
        title: 'Update order'
      },
      "noFooter": true,
      "modalSize": "lg"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.CloseModal('updateorders');
      }
    }
  }, [_c('div', {
    staticClass: "bg-white rounded-lg"
  }, [_c('div', {
    staticClass: "rounded-lg flex"
  }, [_c('div', {
    staticClass: "w-1/4 flex justify-center items-center"
  }, [_c('span', {
    staticClass: "w-16 h-16 bg-green-600 text-white p-3 rounded-full"
  }, [_c('i', {
    staticClass: "material-icons",
    staticStyle: {
      "font-size": "40px"
    }
  }, [_vm._v("update")])])]), _c('div', {
    staticClass: "w-full pr-4 mt-4"
  }, _vm._l(_vm.order.details, function (it, indx) {
    return _c('div', {
      key: indx,
      staticClass: "grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-4 mt-4"
    }, [_c('div', {
      staticClass: "grid grid-cols-1"
    }, [_c('div', {
      staticClass: "flex items-center maxx-heigh"
    }, [_c('div', {
      staticClass: "flex-shrink-0 w-10 h-10"
    }, [_c('img', {
      staticClass: "h-10 w-10 rounded-md mx-auto",
      attrs: {
        "alt": "...",
        "src": it.product.picture ? it.product.picture : 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
      }
    })]), _c('div', {
      staticClass: "ml-3"
    }, [_vm._v(_vm._s(it.product.name))])])]), _c('div', {
      staticClass: "grid grid-cols-1"
    }, [_c('div', {
      staticClass: "border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
    }, [_c('div', {
      staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
    }, [_c('p', [_c('label', {
      staticClass: "bg-white text-gray-700 px-1",
      attrs: {
        "for": "fullname"
      }
    }, [_vm._v(_vm._s(_vm.$t("unit_price")) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()])])]), _c('p', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: it.unitPrice,
        expression: "it.unitPrice"
      }],
      staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
      attrs: {
        "type": "number"
      },
      domProps: {
        "value": it.unitPrice
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(it, "unitPrice", $event.target.value);
        }
      }
    })])])]), _c('div', {
      staticClass: "grid grid-cols-1"
    }, [_c('div', {
      staticClass: "border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
    }, [_c('div', {
      staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
    }, [_c('p', [_c('label', {
      staticClass: "bg-white text-gray-700 px-1",
      attrs: {
        "for": "Email"
      }
    }, [_vm._v(_vm._s(_vm.$t("quantity")))])])]), _c('p', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: it.quantity,
        expression: "it.quantity"
      }],
      staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
      attrs: {
        "type": "number"
      },
      domProps: {
        "value": it.quantity
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(it, "quantity", $event.target.value);
        }
      }
    })])])])]);
  }), 0)]), _c('div', {
    staticClass: "p-4 flex space-x-4"
  }, [_c('a', {
    staticClass: "w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400 font-bold rounded-lg text-sm",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        return _vm.CloseModal('updateorders');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("cancel")))]), _c('a', {
    staticClass: "w-1/2 px-4 py-3 text-center text-blue-100 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white font-bold text-sm loidingconfirm relative",
    class: _vm.loadingupdateorder ? 'noselect' : '',
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        return _vm.UpdateOrder();
      }
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.loadingupdateorder,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loadingupdateorder = $event;
      }
    }
  }), _vm._v(_vm._s(_vm.$t("confirme")) + " ")], 1)])])]), _c('vue-modal-2', {
    attrs: {
      "name": "myModalCancel",
      "headerOptions": {
        title: _vm.$t('cancelation_order')
      },
      "noFooter": true,
      "modalSize": "lg"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.CloseModal('myModalCancel');
      }
    }
  }, [_c('div', {
    staticClass: "relative flex flex-col w-full h-auto"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _c('div', {
    staticClass: "bg-white rounded px-8 flex flex-col"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('label', {
    staticClass: "block text-grey-darker text-sm font-bold mb-2",
    attrs: {
      "for": "content"
    }
  }, [_vm._v(_vm._s(_vm.$t("reason_for_cancellation")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])]), _c('p', [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.motifAnnulation,
      expression: "motifAnnulation"
    }],
    staticClass: "focus:outline-none shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-grey-darker mb-3",
    attrs: {
      "id": "content",
      "cols": "10",
      "rows": "3",
      "placeholder": _vm.$t('content_hint')
    },
    domProps: {
      "value": _vm.motifAnnulation
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.motifAnnulation = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "flex space-x-2"
  }, [_c('button', {
    staticClass: "w-full mt-2 bg-blue-600 focus:outline-none hover:bg-blue-500 text-white font-semibold p-3",
    on: {
      "click": function click($event) {
        return _vm.cancelOrder(_vm.order);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel_order")) + " ")])])])])], 1)]), _c('vue-modal-2', {
    attrs: {
      "name": "myModalRemonded",
      "headerOptions": {
        title: _vm.$t('remonded_order')
      },
      "noFooter": true,
      "modalSize": "lg"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.CloseModal('myModalRemonded');
      }
    }
  }, [_c('div', {
    staticClass: "relative flex flex-col w-full h-auto"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _c('div', {
    staticClass: "bg-white rounded px-8 pt-6 pb-8 flex flex-col"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('label', {
    staticClass: "block text-grey-darker text-sm font-bold mb-2",
    attrs: {
      "for": "dateremind"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("remind_date")) + " : ")]), _c('datetime-v2', {
    attrs: {
      "type": "datetime",
      "placeholder": _vm.$t('remind_date'),
      "input-class": "focus:outline-none shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
    },
    model: {
      value: _vm.remindDate,
      callback: function callback($$v) {
        _vm.remindDate = $$v;
      },
      expression: "remindDate"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_c('label', {
    staticClass: "block text-grey-darker text-sm font-bold mb-2",
    attrs: {
      "for": "content"
    }
  }, [_vm._v(_vm._s(_vm.$t("content")))]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.contentRemend,
      expression: "contentRemend"
    }],
    staticClass: "focus:outline-none shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3",
    attrs: {
      "id": "content",
      "cols": "10",
      "rows": "3",
      "placeholder": _vm.$t('content_hint')
    },
    domProps: {
      "value": _vm.contentRemend
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.contentRemend = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "flex items-center justify-between"
  }, [_c('button', {
    staticClass: "bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.RemindOrder(_vm.order);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")])])])], 1)]), _c('vue-modal-2', {
    attrs: {
      "name": "myModalUnrached",
      "headerOptions": {
        title: _vm.$t('unrached_order')
      },
      "noFooter": true,
      "modalSize": "lg"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.CloseModal('myModalUnrached');
      }
    }
  }, [_c('div', {
    staticClass: "flex flex-col w-full h-auto"
  }, [_c('div', {
    staticClass: "bg-white rounded px-8 pt-6 pb-8 flex flex-col"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _c('div', {
    staticClass: "my-6"
  }, [_c('div', {
    staticClass: "border seller-select h-10 hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "seller"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("reason_unreached")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    attrs: {
      "options": _vm.commentsUnrach
    },
    model: {
      value: _vm.motifUnrached,
      callback: function callback($$v) {
        _vm.motifUnrached = $$v;
      },
      expression: "motifUnrached"
    }
  })], 1)])]), _c('div', {
    staticClass: "flex items-center justify-between"
  }, [_c('button', {
    staticClass: "bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded",
    attrs: {
      "disabled": _vm.loading,
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.UnreachedOrder(_vm.order);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirm_unreached")) + " ")])])], 1)])]), _c('vue-modal-2', {
    staticClass: "moukhtar",
    attrs: {
      "name": "ModalDouble",
      "headerOptions": {
        title: 'Double orders'
      },
      "noFooter": true,
      "modalSize": "xl"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.CloseModal('ModalDouble');
      }
    }
  }, [_c('div', {
    staticClass: "flex flex-col w-full h-auto p-5 pt-0 bottom_bitt"
  }, [_c('div', {
    staticClass: "align-middle inline-block min-w-full overflow-hidden bg-white shadow-dashboard rounded-bl-lg rounded-br-lg",
    staticStyle: {
      "margin-bottom": "62px"
    }
  }, [_c('table', {
    staticClass: "min-w-full"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left leading-4 text-green-600 tracking-wider"
  }, [_vm._v(" ID order ")]), _c('th', {
    staticClass: "px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("customer")) + " ")]), _c('th', {
    staticClass: "px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("products")) + " ")]), _c('th', {
    staticClass: "px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("total_price")) + " ")]), _c('th', {
    staticClass: "px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("status")) + " ")]), _c('th', {
    staticClass: "px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-right text-sm leading-4 text-green-600 tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("action")) + " ")])])]), _c('tbody', {
    staticClass: "bg-white"
  }, _vm._l(_vm.dataDouble, function (order, ind) {
    return _c('tr', {
      key: ind
    }, [_c('td', {
      staticClass: "border-t-2 border-gray-300 px-3 py-1"
    }, [_c('div', {
      staticClass: "flex items-center"
    }, [_c('div', [_c('div', {
      staticClass: "text-sm leading-5 text-gray-800"
    }, [_vm._v(" " + _vm._s(order.id) + " ")])])])]), _c('td', {
      staticClass: "border-t-2 border-gray-300 px-3 py-1"
    }, [_c('div', {
      staticClass: "text-sm leading-5 siz12 text-blue-900"
    }, [_c('div', {
      staticClass: "flex-grow-1"
    }, [_c('h5', {
      staticClass: "fs-14 mb-1"
    }, [_vm._v(" " + _vm._s(order.customer.fullName) + " ")]), _c('p', {
      staticClass: "badge badge-soft-success text-uppercase"
    }, [_vm._v(" " + _vm._s(order.customer.phone) + " ")])])])]), _c('td', {
      staticClass: "border-t-2 border-gray-300 px-3 py-1"
    }, [_c('div', {
      staticClass: "text-sm leading-5 text-blue-900"
    }, [_vm._v(" " + _vm._s(_vm.sumProduct(order.details)) + " ")])]), _c('td', {
      staticClass: "px-3 py-1 text-left border-t-2 border-gray-300 whitespace-no-wrap"
    }, [_c('div', {
      staticClass: "text-sm leading-5 text-blue-900"
    }, [_vm._v(" " + _vm._s(order.totalPrice) + " ")])]), _c('td', {
      staticClass: "border-t-2 border-gray-300 px-3 py-1"
    }, [_c('div', {
      staticClass: "text-sm leading-5 text-blue-900",
      style: {
        color: order.status.name ? order.status.color : '',
        borderColor: order.status.name ? order.status.color : ''
      }
    }, [_vm._v(" " + _vm._s(order.status.name) + " ")])]), _c('td', {
      staticClass: "border-t-2 border-gray-300 px-3 py-1 text-right"
    }, [_c('router-link', {
      staticClass: "text-sm leading-5 px-2 rounded-full text-xs text-blue-900 cursor-pointer font-medium",
      attrs: {
        "target": "_blank",
        "to": {
          path: "/orders/detail/".concat(order._id)
        }
      }
    }, [_c('i', {
      staticClass: "material-icons mx-2"
    }, [_vm._v(" remove_red_eye")])])], 1)]);
  }), 0)])])]), _c('div', {
    staticClass: "modal-footer px-5 border0-t text-right"
  }, [_c('button', {
    staticClass: "flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right",
    on: {
      "click": function click($event) {
        return _vm.CloseModal('ModalDouble');
      }
    }
  }, [_vm._v(" Cancel ")])])])], 1)]) : _vm._e()]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container mx-auto px-40 py-4 pt-6"
  }, [_c('div', {
    attrs: {
      "id": "gettingValuesExample"
    }
  }, [_c('div', {
    staticClass: "flip-clock"
  }, [_c('span', {
    staticClass: "flip-clock__piece flip"
  }, [_c('span', {
    staticClass: "flip-clock__card flip-card"
  }, [_c('b', {
    staticClass: "flip-card__top hours"
  }, [_vm._v("0")])]), _c('span', {
    staticClass: "flip-clock__slot"
  }, [_vm._v("Hours")])]), _c('span', {
    staticClass: "flip-clock__piece flip"
  }, [_c('span', {
    staticClass: "flip-clock__card flip-card"
  }, [_c('b', {
    staticClass: "flip-card__top minutes"
  }, [_vm._v("0")])]), _c('span', {
    staticClass: "flip-clock__slot"
  }, [_vm._v("minutes")])]), _c('span', {
    staticClass: "flip-clock__piece flip"
  }, [_c('span', {
    staticClass: "flip-clock__card flip-card"
  }, [_c('b', {
    staticClass: "flip-card__top seconds"
  }, [_vm._v("0")])]), _c('span', {
    staticClass: "flip-clock__slot"
  }, [_vm._v("seconds")])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "facebook-spinner"
  }, [_c('div', {
    staticClass: "spinner-block block-1"
  }), _c('div', {
    staticClass: "spinner-block block-2"
  }), _c('div', {
    staticClass: "spinner-block block-3"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-16 h-16 p-2"
  }, [_c('i', {
    staticClass: "material-icons icon-call"
  }, [_vm._v("bookmark_border")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-16 h-16 p-2"
  }, [_c('i', {
    staticClass: "material-icons icon-call"
  }, [_vm._v("date_range")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-16 h-16 p-2"
  }, [_c('i', {
    staticClass: "material-icons icon-call"
  }, [_vm._v("contacts")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-16 h-16 p-2"
  }, [_c('i', {
    staticClass: "material-icons icon-call"
  }, [_vm._v("local_grocery_store")])]);

}]

export { render, staticRenderFns }